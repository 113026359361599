@import '_styles';

.header-content {
    @include size-reading-block;
    // margin: auto;
    margin-inline: auto;
    padding-top: base(3);
    max-width: none;
    padding-left: 0;
}

.header-delimiter {
    width: base(2);
}

.header-title {
    margin-bottom: 0;
}

.header-image {
    width: 100%;
    max-width: unset;
    max-height: 75vh;
}

.header-meta {
    display: flex;
    margin-bottom: $gutter;
}

.header-date {
    @extend %label;
    color: $gray;
    &:not(:last-child)::after { content: '\00a0-\00a0'; }
}
@import '_styles';

.flex-content-inline-header {
    display: flex;
    margin: auto;
    flex-wrap: wrap;

    .header-content {
        margin: unset;
        padding-top: 0px;
        width: 100%;
    }

    .content-block {
        width: 100%;
    }

    @include small-begin {
        padding-top: base(3);
    }

    @include large-begin {
        flex-wrap: nowrap;

        .header-content,
        .content-block {
            width: 50%;
        }
    }
}

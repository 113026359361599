@import '_styles';

.animated-page-header-wrapper {
    position: relative;
    
    .animated-page-header {
        position: relative;

        .header-content-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            > .html-block:last-of-type > *:last-child { margin-bottom: 0; }

            .btn,
            .header-scroll-to { margin-top: $base; }
        }

        @include size-content-block;
        position: relative;
        margin-bottom: base(4);
        margin-top: base(1);
        display: flex;
        align-items: center;

        .header-content-wrapper {
            order: 1;
            width: 50%;
            flex-shrink: 0;
            padding: base(2) 0;
            z-index: 1;
        }

        .header-media-wrapper {
            width: 50vw;
            height: auto;
            order: 2;

            video,
            .header-image,
            .header-video {
                width: 50vw;
            }
        }

        .header-title {
            position: relative;
            width: calc(100% + #{base(24)});
            left: 0;
            right: - base(24);
            min-height: 180px;

            h1 {
                margin-bottom: 0px;
            }
        }

        .header-content {
            padding-right: base(2);
            padding-top: base(1);
            margin-left: 0;
            margin-right: 0;

            &::after {
                content: '';
                background-color: white;
                height: $stroke-width;
                width: base(2);
                display: block;
                margin-top: base(2);
            }
        }

        /////////////////////////////
        // MEDIA QUERIES
        /////////////////////////////

        @include mid-width-break {
            flex-direction: column;
            margin-bottom: base(2);

            .header-media-wrapper {
                position: relative;
                width: 100%;
                order: 1;

                video,
                .header-image,
                .header-video {
                    width: 100%;
                    max-height: unset;
                }
            }

            .header-title,
            .header-content {
                width: 100%;
            }

            .header-content-wrapper {
                order: 2;
                width: 100%;
                padding-top: 0;
                padding-bottom: base(2);
            }

            .header-content {
                padding-right: 0;
            }
            
            .header-title {
                min-height: 135px;
            }
        }

        @include small-break {
            margin-top: $gutter;

            .header-title {
                min-height: 70px;
            }
        }
    }

    .header-watermark-wrapper {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 0;
        right: 50px;
        z-index: 1;

        .header-watermark {
            height: 200px;
        }

        @include mid-width-break {
            right: 25px;
            bottom: unset;
            top: 25px;

            .header-watermark {
                height: 100px;
            }
        }
    }
}

@import '_styles';

.template-contact {

	.form-outer-wrapper {
		@include col(7);
	}
	
	.content {
		@include col(4);
		@include push(1);
		padding-top: $baseline;
	}
	
	/////////////////////////////
	// MEDIA QUERIES
	/////////////////////////////
	
	@include mid-width-break {
		.form-outer-wrapper {
			@include col(12);
			order: 2;
		}
	
		.content {
			@include col(12);
			margin-left: 0;
			margin-right: 0;
			margin-bottom: base(2);
			padding-top: 0;
			order: 1;
		}
	}
}
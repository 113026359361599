@import '_styles';

.mobile-menu-modal {
	@include size-content-block;
	display: flex;
	flex-direction: column;

	nav {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-shrink: 0;
		flex-grow: 1;
		padding: base(2) 0;

		> * {
			@extend %h1;
			&:last-child { margin-bottom: 0; }
		}
	}
}
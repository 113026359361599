/////////////////////////////
// MEDIA QUERIES
/////////////////////////////

@mixin tiny-break {
	@media (max-width: #{$tiny-width}) {
		@content;
	}
}

@mixin small-break {
	@media (max-width: #{$mobile-width}) {
		@content;
	}
}

@mixin mid-break {
	@media (max-height: #{$tablet-height}), (max-width: #{$tablet-width}) {
		@content;
	}
}

@mixin mid-width-break {
	@media (max-width: #{$tablet-width}) {
		@content;
	}
}

@mixin large-break {
	@media (max-width: #{$large-width}) {
		@content;
	}
}

@mixin small-begin {
	@media (min-width: #{$mobile-width}) {
		@content;
	}
}

@mixin mid-width-begin {
	@media (min-width: #{$tablet-width}) {
		@content;
	}
}

@mixin large-begin {
	@media (min-width: #{$large-width}) {
		@content;
	}
}

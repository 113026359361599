@charset "UTF-8";
.advanced-blockquote {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3.33333rem;
  padding-right: 3.33333rem;
  width: 100%;
  margin-top: 10rem;
  margin-bottom: 13.33333rem; }
  @media (max-height: 700px), (max-width: 1000px) {
    .advanced-blockquote {
      padding-left: 1.66667rem;
      padding-right: 1.66667rem; } }
  @media (max-width: 600px) {
    .advanced-blockquote {
      padding-left: 0.83333rem;
      padding-right: 0.83333rem; } }
  .advanced-blockquote.margin-large {
    margin-bottom: 6.66667rem; }
  .advanced-blockquote.margin-medium {
    margin-bottom: 5rem; }
  .advanced-blockquote.margin-small {
    margin-bottom: 1.66667rem; }
  .advanced-blockquote.margin-none {
    margin-bottom: 0; }
  @media (max-width: 600px) {
    .advanced-blockquote.margin-large {
      margin-bottom: 5rem; }
    .advanced-blockquote.margin-medium {
      margin-bottom: 3.33333rem; }
    .advanced-blockquote.margin-small {
      margin-bottom: 1.66667rem; } }
  @media (max-width: 600px) {
    .advanced-blockquote {
      margin-bottom: 6.66667rem; } }
  .advanced-blockquote .advanced-blockquote-outter-wrapper {
    position: relative; }
  .advanced-blockquote .advanced-blockquote-content-wrapper {
    display: flex;
    align-items: flex-start; }
  .advanced-blockquote .advanced-blockquote-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 3.33333rem; }
  .advanced-blockquote .advanced-blockquote-quote {
    width: 75%; }
    .advanced-blockquote .advanced-blockquote-quote blockquote {
      font-size: 1.5rem; }
    .advanced-blockquote .advanced-blockquote-quote h3 {
      text-align: center; }
    @media (max-width: 1000px) {
      .advanced-blockquote .advanced-blockquote-quote {
        width: 90%; }
        .advanced-blockquote .advanced-blockquote-quote h3 {
          text-align: right; } }
  .advanced-blockquote .advanced-blockquote-quote-container {
    position: relative;
    border: 2px solid white;
    padding: 0.83333rem 1.66667rem 0.83333rem 2.5rem; }
    @media (max-width: 1000px) {
      .advanced-blockquote .advanced-blockquote-quote-container {
        padding: 0.83333rem; } }
    .advanced-blockquote .advanced-blockquote-quote-container:before {
      content: '“';
      font-size: 8rem;
      left: 6.66667rem;
      top: 0;
      position: absolute;
      text-shadow: 4px 0px 8px black;
      padding: 1rem; }
    .advanced-blockquote .advanced-blockquote-quote-container:after {
      content: '”';
      font-size: 8rem;
      right: 6.66667rem;
      bottom: -4.16667rem;
      position: absolute;
      text-shadow: 0px 0px 8px black;
      padding: 1rem; }
  .advanced-blockquote .advanced-blockquote-excerpt {
    width: calc(33% - 3.33333rem); }
  .advanced-blockquote .advanced-blockquote-media-wrapper {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 40%;
    margin-top: -6.66667rem; }
    @media (max-width: 1000px) {
      .advanced-blockquote .advanced-blockquote-media-wrapper {
        margin-top: -13.33333rem; } }
  .advanced-blockquote .advanced-blockquote-image {
    position: relative;
    z-index: -1;
    width: 75%; }
    @media (max-width: 1000px) {
      .advanced-blockquote .advanced-blockquote-image {
        width: 50%; } }
  .advanced-blockquote hr {
    width: 3.33333rem;
    margin-inline: auto;
    margin-bottom: 1.66667rem; }
    @media (max-width: 1000px) {
      .advanced-blockquote hr {
        margin: 3.33333rem 0 2.5rem; } }
  .advanced-blockquote.right .advanced-blockquote-media-wrapper {
    left: 0; }
  .advanced-blockquote.right .advanced-blockquote-content-wrapper {
    margin-left: auto; }
  .advanced-blockquote.right .advanced-blockquote-title {
    margin-left: auto; }
  .advanced-blockquote.right .advanced-blockquote-content {
    padding-right: 3.33333rem;
    align-items: flex-end; }
  .advanced-blockquote.right .advanced-blockquote-video {
    margin-left: 3.33333rem; }
  @media (max-width: 1000px) {
    .advanced-blockquote .advanced-blockquote-title,
    .advanced-blockquote .advanced-blockquote-excerpt {
      width: 100%; }
    .advanced-blockquote .advanced-blockquote-content {
      margin-bottom: 1.66667rem; }
    .advanced-blockquote .advanced-blockquote-media-wrapper {
      position: relative;
      width: calc(100% - 3.33333rem); } }
  @media (max-width: 600px) {
    .advanced-blockquote .advanced-blockquote-media-wrapper {
      width: calc(100% - 2.5rem); }
    .advanced-blockquote.left .advanced-blockquote-content {
      padding-left: 1.66667rem; }
    .advanced-blockquote.right .advanced-blockquote-content {
      padding-right: 1.66667rem; } }

@import '_styles';

.card.type-project {

	.card-outter-wrapper {
		position: relative;
	}

	.card-content-wrapper {
		display: flex;
		align-items: flex-start;
	}

	.card-content {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-top: base(2);
	}

	.card-title {
		width: 50%;
		h2 {
			@extend %textShadow;
			position: relative;
			margin-bottom: 0;
			&:hover { text-decoration: underline; }
		}
	}

	.card-excerpt { width: calc(33% - #{base(2)}); }

	.card-media-wrapper {
		position: absolute;
		top: 0;
		z-index: -1;
		width: 66%;
	}

	.card-video {
		width: calc(100% - #{base(2)});
		margin-bottom: - base(4);
	}

	.card-image {
		position: relative;
		z-index: -1;
		width: 75%;
	}

	aside {
		@extend %label;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		position: relative;
	}

	.card-index { display: block; margin-bottom: base(2); }

	.card-client {
		white-space: nowrap;
		transform: rotate(90deg) translateY(-50%);
		transform-origin: top left;
		position: absolute;
		top: 100%;
		color: $gray;
		left: 50%;
	}

	hr { width: $base; }

	/////////////////////////////
	// EVEN / ODD VARIATIONS
	/////////////////////////////

	&:nth-child(odd) {

		.card-media-wrapper { right: 0; }

		.card-content {
			align-items: flex-start;
			padding-left: base(2);
			order: 2;
		}

		.card-video { margin-right: base(2); }

		.card-image { margin-left: auto; }

		aside { order: 1; }
	}

	&:nth-child(even) {
		.card-media-wrapper { left: 0; }

		.card-content-wrapper {
			text-align: right;
			margin-left: auto;
		}

		.card-title { margin-left: auto; }

		.card-content {
			padding-right: base(2);
			align-items: flex-end;
		}

		.card-video { margin-left: base(2); }

		hr { margin-left: auto; }
	}

	/////////////////////////////
	// MEDIA QUERIES
	/////////////////////////////

	@include mid-width-break {

		.card-title,
		.card-excerpt { width: 100%; }

		.card-content { margin-bottom: $base; }

		.card-media-wrapper {
			position: relative;
			width: calc(100% - #{base(2)});
		}

		&:nth-child(odd) {
			.card-media-wrapper { margin-left: auto; }
		}
	}

	@include small-break {

		.card-media-wrapper { width: calc(100% - #{base(1.5)}); }

		&:nth-child(odd) {
			.card-content { padding-left: $base; }
		}

		&:nth-child(even) {
			.card-content { padding-right: $base; }
		}
	}
}

@import '_styles.scss';

.blockquote {
	@include size-content-block;
	@include cms-margin;

	blockquote {
		margin: 0;
		position: relative;
		padding-left: $base;

		&::before {
			content: '';
			height: 100%;
			width: $stroke-width;
			background-color: $gray;
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	.quotee {
		margin-top: $gutter;
		padding-left: $base;
	}
}
@import '_styles';

.template-inner-wrapper {
	@include size-content-block;
	padding-bottom: base(2);
}

.form-outer-wrapper {
	@include col(12);
}

form {
	@include row;

	.status { @include formStatus; }

	.tooltip {
		position: relative;
		display: inline-flex;
		left: 0;
		background-color: $red;
		span { border-top-color: $red; }
	}

	.interact { 
		@include col(12); 
	}

	.half {
		@include col(6);
		@include col-break-mid;
	}

	.third {
		@include col(4);
		@include col-break-mid;
	}

	.btn { margin-left: $gutter; }
}
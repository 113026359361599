@import '_styles';

.toggle-modal {
	background: none;
	border: 0;
	padding: 0;
	color: inherit;
	cursor: pointer;
	text-align: inherit;
	margin: 0;
	&:focus { outline: none; }
}
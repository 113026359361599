@import '_styles';

.header-main {
	padding: $base $base 0;
	position: fixed;
	width: 100%;
	z-index: $z-header;

	.header-inner-wrapper {
		display: flex;
		align-items: center;

		> *:not(.modal) {
			position: relative;
			z-index: $z-header;
		}
	}

	.header-logo {
		display: inline-flex;
		margin-right: auto;

		.graphic.logo {
			@include color-svg(white);
			height: base(1.5);
			margin-right: auto;
		}
	}

	.header-menu-items {
		> * {
			@extend %label;
			color: white;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				transform: translateY(100%);
				left: -2px;
				width: 100%;
				height: $stroke-width;
				background-color: white;
				display: block;
				opacity: 0;
			}

			&:hover {
				text-decoration: none;
				&::after {
					opacity: 1;
				}
			}

			&:not(:last-child) {
				margin-right: $base;
			}
		}
	}

	.mobile-modal-toggler {
		display: none;
		.graphic.hamburger { @include color-svg(white); }
	}

	/////////////////////////////
	// MEDIA QUERIES
	/////////////////////////////

	@include small-break {
		padding: $gutter $gutter 0;

		.header-menu-items { display: none; }

		.mobile-modal-toggler { display: block; }
	}
}
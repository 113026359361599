@import '_styles';

.image-grid {
	@include size-content-block;
	@include cms-margin;

	.image {
		width: calc(25% - 2px);
		margin: 1px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@include mid-break {
		.image { width: calc(33.33333% - 2px); }
	}

	@include small-break {
		.image { width: calc(50% - 2px); }
	}

	@include tiny-break {
		.image {
			width: 100%;
		}
	}

	&.basic-gallery {
		.image {
			background-color: $black-trans;
			padding: base(2);
		}

		@include tiny-break {
			.image {
				padding: base(2) base(4);
			}
		}
	}

	&.dynamic-gallery {
		.dynamic-image {
			transition: transform .3s ease-in-out;
			cursor: pointer;
			padding: .5rem;

			&:hover {
				transform: scale(1.2);
			}
		}

		.toggle-modal {
			width: 100%;

			img {
				width: 100%;
				height: auto;
				object-fit: cover;
			}
		}
	}
}
@import '_styles';

.template-blog {

	.template-title {
		@include size-wide-block;
		margin-top: base(4);
		margin-bottom: base(4);
	}

	.taxonomy-filter-results {
		@include size-wide-block;
		padding-bottom: base(8);
		list-style: none;
	}

	.results-row { @include row; }

	.card {
		@include col(6);
		margin-bottom: $gutter;
		&:nth-child(even) { padding-left: base(2); }
		&:nth-child(odd) { padding-right: base(2); }
	}

	/////////////////////////////
	// MEDIA QUERIES
	/////////////////////////////

	@include mid-break {
		.card {
			@include col(12);
			&:nth-child(even) { padding-left: $gutter; }
			&:nth-child(odd) { padding-right: $gutter; }
		}
	}

	@include small-break {
		.template-title {
			margin-top: base(2);
			margin-bottom: base(2);
		}
	}
}
@import '_styles';

.template-home {

	.home-featured-projects {
		@include size-wide-block;
	}

	.card { margin-bottom: base(8); }
}

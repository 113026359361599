@import '_styles';

.btn {
	@include btn-reset;
	display: inline-flex;
	flex-wrap: wrap;
	transition: all $trans-time $trans-curve;
	align-items: center;
	flex-shrink: 0;
	color: white;

	.btn-label {
		@extend %label;
		display: inline-flex;
	}

	&:hover .graphic {
		transform: translateX(base(.25));
	}

	/////////////////////////////
	// TYPE / COLOR VARIATIONS
	/////////////////////////////

	&.type-submit,
	&.type-primary {
		padding: $gutter;
		outline: $stroke-width solid currentColor;
	}

	&.color-gray,
	&.type-text {
		color: $gray;
		&:hover { color: white; }
	}

	/////////////////////////////
	// GRAPHICS
	/////////////////////////////

	.graphic {
		@include color-svg(currentColor);
		width: $gutter;
		height: $gutter;
		transform: translateX(0);
		transition: transform $trans-time $trans-curve;
		&:not(:first-child) { margin-left: $gutter; }
	}
}

.btn-reset {
	@include btn-reset;
}

@import '_styles';

.footer {
	position: fixed;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	bottom: 0;
	right: 0;
	z-index: $z-header;
	transition: opacity $trans-time $trans-curve;
	padding: $base;
	width: 50%;
	align-self: flex-end;

	.footer-copyright {
		@extend %label;
		color: $gray;
		text-align: right;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.footer-social {
		display: flex;
		margin-left: $gutter;

		> * {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: white;
			padding: base(.25);
			color: black;
			&:not(:last-child) { margin-right: $gutter; }
		}

		.graphic {
			width: base(.33);
			height: base(.33);
		}
	}

	//////////////////////////////
	// MEDIA QUERIES
	//////////////////////////////

	@include mid-width-break {
		justify-content: flex-start;
		position: relative;
		width: 100%;
		margin-bottom: base(2);

		.footer-copyright { text-align: left; }
	}

	@include small-break {
		padding: $gutter;
	}
}

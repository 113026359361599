@import '_styles';

.categories-list {
	@extend %label;
	display: flex;
	color: $gray;

	> * {
		margin-bottom: 0;
		&:not(:last-child)::after { content: ',\00a0'; }
	}
}
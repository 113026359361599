@import '_styles';

.people-grid {
	@include size-content-block;
	@include cms-margin;

	.grid-item { @include col(4); }

	.toggle-modal {
		&:hover .person-title { text-decoration: underline; }
	}

	.person-position {
		@extend %label;
		color: $gray;
		margin-bottom: - $base;
		position: relative;
		position: relative;
		z-index: 2;

		&::after {
			content: '';
			display: block;
			margin-top: $gutter;
			height: base(2);
			width: $stroke-width;
			background-color: $gray;
			left: $gutter;
			position: relative;
		}
	}

	.toggle-modal { width: 100%; }

	.person-image {
		position: relative;
		height: base(16);
		&.has-hover .image-thumbnail:hover { opacity: 0; }
	}

	.image-thumbnail,
	.thumbnail-hover {
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.image-thumbnail {
		position: relative;
		transition: opacity $trans-time $trans-curve;
		z-index: 1;
		opacity: 1;
		&.no-image { background-color: $black; }
	}

	.thumbnail-hover {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	//////////////////////////////
	// MEDIA QUERIES
	//////////////////////////////

	@include mid-width-break {
		.grid-item {
			@include col(6);
		}
	}

	@include small-break {
		.grid-item {
			@include col(12);
		}
	}
}
@import '_styles';

.embedded-image {
	&.cover {
		background-size: cover;
		overflow: hidden;

		img {
			visibility: hidden;
			position: absolute;
			z-index: -1;
		}
	}

	&.no-image {
		background: $black;
		padding-bottom: 56.25%;
	}
}
@import '_styles';

.paginator {
	@include size-content-block;
	margin-bottom: base(4);

	ul {
		display: flex;
		list-style: none;

		li {
			text-align: center;
			width: $base;
			height: $base;
			line-height: $base;
			font-weight: bold;
			color: $gray;

			&:not(:last-child) { margin-right: $gutter }

			&.paginator-ellipsis { color: $gray; }

			> * {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100%;
				transition: all $trans-time $trans-curve;
				border-width: $stroke-width;
				border-color: transparent;
				border-style: solid;

				&:hover { border-color: white; color: white; }
			}
		}

		span { border-color: white; color: white; }
	}
}

@import '_vars';
@import '_structure';

/////////////////////////////
// HEADINGS
/////////////////////////////

%h1, %h2, %h3, %h4, %h5 {
	font-family: $font;
}

%h1 {
	margin: 0 0 base(1.5);
	font-size: $h1-font-size;
	line-height: $h1-line-height;
	font-weight: bold;

	@include small-break {
		font-size: $h2-font-size;
		line-height: $h2-line-height;
	}

	@include tiny-break {
		font-size: $h3-font-size;
		line-height: $h3-line-height;
	}

	&.mega {
		font-size: $h1-mega-font-size;
		line-height: $h1-mega-line-height;

		@include small-break {
			font-size: $h1-font-size;
			line-height: $h1-line-height;
		}

		@include tiny-break {
			font-size: $h2-font-size;
			line-height: $h2-line-height;
		}
	}
}

%h2 {
	margin: 0 0 $base;
	font-size: $h2-font-size;
	line-height: $h2-line-height;
	font-weight: bold;

	@include small-break {
		font-size: $h3-font-size;
		line-height: $h3-line-height;
	}

	@include tiny-break {
		font-size: $h4-font-size;
		line-height: $h4-line-height;
		letter-spacing: $h4-letter-spacing;
	}
}

%h3 {
	margin: 0 0 $base;
	font-size: $h3-font-size;
	line-height: $h3-line-height;
	font-weight: bold;

	@include small-break {
		font-size: $h4-font-size;
		line-height: $h4-line-height;
		letter-spacing: $h4-letter-spacing;
	}

	@include tiny-break {
		font-size: $h5-font-size;
		line-height: $h5-line-height;
		letter-spacing: $h5-letter-spacing;
	}
}

%h4 {
	margin: 0 0 $gutter;
	font-size: $h4-font-size;
	line-height: $h4-line-height;
	letter-spacing: $h4-letter-spacing;
	font-weight: normal;

	@include small-break {
		font-size: $h5-font-size;
		line-height: $h5-line-height;
		letter-spacing: $h5-letter-spacing;
	}
}

%h5 {
	margin: 0 0 $gutter;
	font-size: $h5-font-size;
	line-height: $h5-line-height;
	letter-spacing: $h5-letter-spacing;
	font-weight: normal;
}

%p {
	margin: 0 0 $base;
	line-height: 1.75;
	@include small-break {
		margin: 0 0 $gutter;
	}
}

%anchor {
	text-decoration: none;
	font-weight: bold;
	cursor: pointer;
	color: inherit;
	&:hover { text-decoration: underline; }
}

/////////////////////////////
// OTHER TYPE STYLES
/////////////////////////////

%large-body {
	font-family: $font;
	font-size: $large-font-size;
	line-height: $large-line-height;
}

%small-body {
	font-size: $small-font-size;
	line-height: $small-line-height;
}

%label {
	font-family: $font;
	font-size: $label-font-size;
	line-height: 1;
	letter-spacing: $label-letter-spacing;
	text-transform: uppercase;
	-webkit-font-smoothing: auto;
}

%blockquote {
	@extend %large-body;
	margin: 0;
	position: relative;
	padding-left: base(3);

	&:before {
		content: '“';
		font-family: $font;
		font-size: base(4.5);
		line-height: base(4.5);
		position: absolute;
		left: 0;
		top: 0;
	}

	.html-block {
		@extend %large-body;
	}

	p {
		margin-bottom: $gutter;
	}

	cite {
		position: relative;
		font-style: normal;
		font-weight: bold;
		padding-left: base(2.25);
		font-family: $font;
		font-size: $base;

		&:before {
			content: ' ';
			height: $stroke-width;
			width: base(1.5);
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -1px;
			background: $black;
		}
	}

	@include mid-break {
		padding-left: base(1.5);
		padding-right: 0;

		&:before {
			font-size: base(3);
			line-height: base(3);
		}
	}
}

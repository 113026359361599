@import '_styles';

.close-modal {
	@extend %label;
	display: flex;
	align-items: center;
	cursor: pointer;
	line-height: 1;

	&::after {
		content: '';
		background-color: white;
		height: $stroke-width;
		width: base(2);
		transition: width $trans-time $trans-curve;
		margin-left: $gutter;
	}

	&:hover::after {
		width: base(2.5);
	}
}
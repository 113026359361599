@import '_styles';

.blog-post-link-container {
    .link-separator {
        display: none;
    }

    @include small-begin {
        .mobile-break {
            display: none;
        }

        .link-separator {
            display: inline-block;
        }
    }
    
    .blog-post-link {
        font-weight: normal;
        margin-right: .5rem;
    
        .arrow-button {
            margin-left: .5rem;
            transform: scale(.6);
        }
    }
    
    .link-separator {
        margin: 0 0.5rem;
    }
}

@import '_styles';

.radio-group-wrap {
	margin-bottom: base(1);

	.group-label {
		margin-bottom: base(.8);
	}

	.input-radio {
		display: flex;
		align-items: center;
		cursor: pointer;
		margin-left: base(.65);
		margin-bottom: base(.5);
	
		.checkbox-wrapper {
			position: relative;
			margin-right: base(.5);
			display: flex;
			border-radius: 100%;
			height: base(1);
			min-width: base(1);
	
			&::after {
				content: '';
				position: absolute;
				height: 50%;
				width: 50%;
				border-radius: 50%;
				left: 50%;
				top: 50%;
				transform: translate3d(-50%, -50%, 0);
				z-index: 1;
			}
	
			&.checked {
				input {
					border-color: rgba($gray, 0.8);
				}
	
				&::after {
					background-color: $gray;
				}
			}
	
			
		}
	
		&:hover .checkbox-wrapper {
			
			&::after {
				background-color: $dark-gray;
			}
	
			&.checked::after { background-color: $gray; }
		}
	
		[type=radio] {
			-webkit-appearance: none;
			cursor: pointer;
			width: 100%;
			height: 100%;
			padding: unset;
			position: relative;
			border-radius: 50%;
			margin: unset;
			&::-ms-check { display: none; }
			&:focus { outline: none; }
		}
	}
	
}
@import '_vars';
@import '_type';
@import '_structure';

.tooltip {
	@extend %label;
	font-weight: bold;
	background-color: $gray;
	position: absolute;
	top: 0;
	left: 50%;
	padding: $gutter;
	color: white;
	margin-bottom: $base;

	span {
		position: absolute;
		transform: translateY(calc(100% - 1px));
		bottom: 0;
		left: 0;
		height: 0;
		width: 0;
		border-style: solid;
		border-width: $gutter $gutter 0 0;
		border-color: transparent;
		border-top-color: $gray;
	}
}

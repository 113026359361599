@import '_queries';
@import '_vars';

/////////////////////////////
// WIDTH
/////////////////////////////

@mixin size-wide-block {
	max-width: $large-width;
	margin-left: auto;
	margin-right: auto;
	padding-left: base(2);
	padding-right: base(2);
	width: 100%;

	@include mid-break {
		padding-left: $base;
		padding-right: $base;
	}

	@include small-break {
		padding-left: $gutter;
		padding-right: $gutter;
	}
}

@mixin size-content-block {
	max-width: $frame-width;
	margin-left: auto;
	margin-right: auto;
	padding-left: base(2);
	padding-right: base(2);
	width: 100%;

	@include mid-break {
		padding-left: $base;
		padding-right: $base;
	}

	@include small-break {
		padding-left: $gutter;
		padding-right: $gutter;
	}
}

@mixin size-reading-block {
	max-width: $reading-width;
	margin-left: auto;
	margin-right: auto;
	padding-left: base(2);
	padding-right: base(2);

	@include mid-break {
		padding-left: $base;
		padding-right: $base;
	}

	@include small-break {
		padding-left: $gutter;
		padding-right: $gutter;
	}
}

/////////////////////////////
// PADDING
/////////////////////////////

@function pad-small-break($i) {
	@return base($i * .5);
}

@mixin pad($i) {
	padding: base($i);
	@include small-break { padding: pad-small-break($i); }
	*:last-child { margin-bottom: 0; }
}

@mixin pad-vert($i) {
	padding-top: base($i);
	padding-bottom: base($i);

	@include small-break {
		padding-top: pad-small-break($i);
		padding-bottom: pad-small-break($i);
	}

	*:last-child { margin-bottom: 0; }
}

/////////////////////////////
// MARGIN
/////////////////////////////

$margin-small-break : .5;

@mixin margin($i) {
	margin-bottom: base($i);
	@include small-break { margin-bottom: base($i * $margin-small-break); }
}

@mixin cms-margin {
	&.margin-large { margin-bottom: base(4); }
	&.margin-medium { margin-bottom: base(3); }
	&.margin-small { margin-bottom: base(1); }
	&.margin-none { margin-bottom: 0; }

	@include small-break {
		&.margin-large { margin-bottom: base(3); }
		&.margin-medium { margin-bottom: base(2); }
		&.margin-small { margin-bottom: $base; }
	}
}

@import '_styles';

.title-content {
	@include size-content-block;
	@include cms-margin;

	.title {
		@include push(2);
		position: relative;
		margin-bottom: 0;

		&::before {
			content: '';
			position: absolute;
			top: $gutter;
			left: 0;
			transform: translateX(calc(-100% - #{$base}));
			height: $stroke-width;
			width: base(2);
			background-color: $gray;
		}
	}

	.content {
		@include col(6);
		> *:last-child { margin-bottom: 0; }
	}

	//////////////////////////////
	// MEDIA QUERIES
	//////////////////////////////

	@include mid-break {
		.title {
			@include col(6);
			margin-left: 0;
			margin-bottom: $base;
		}

		.content { @include col(12); }
	}

	@include small-break {
		.title { @include col(12); }
	}
}

@import '_styles';

.template-post {
	.post-header {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(180deg, rgba(0, 0, 0, .5) 10%, rgba(0, 0, 0, .1) 33%, rgba(0, 0, 0, 0) 100%);
		}
	}

	.flexible-content {
		.content-block {
			@include size-reading-block;
			max-width: none;
		}

		.image-block-wrapper,
		.video-block-wrapper {
			margin-left: auto;
			margin-right: auto;
		}

		.video-block {
			max-width: none;

			&:not(.margin-large) {
				margin-bottom: base(4);
			}
		}

		.blockquote .blockquote-inner-wrapper {
			// @include size-reading-block;
			margin: auto;
		}
	}

	//////////////////////////////
	// MEDIA QUERIES
	//////////////////////////////

	@include small-break {
		.header-content {
			padding-top: base(1.5);
		}
	}
}
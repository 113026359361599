@font-face {
	font-family: 'gt-walsheim';
	src: url('/fonts/gt-walsheim-bold-web.eot');
	src: url('/fonts/gt-walsheim-bold-web.eot?#iefix') format('embedded-opentype'),
		url('/fonts/gt-walsheim-bold-web.woff') format('woff'),
		url('/fonts/gt-walsheim-bold-web.ttf') format('truetype'),
		url('/fonts/gt-walsheim-bold-web.svg#gt-walsheim-bold') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'gt-walsheim';
	src: url('/fonts/gt-walsheim-web.eot');
	src: url('/fonts/gt-walsheim-web.eot?#iefix') format('embedded-opentype'),
		url('/fonts/gt-walsheim-web.woff') format('woff'),
		url('/fonts/gt-walsheim-web.ttf') format('truetype'),
		url('/fonts/gt-walsheim-web.svg#gt-walsheim') format('svg');
	font-weight: 500;
	font-style: normal;
}

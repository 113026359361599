@import '_styles';

.template-projects {
	margin-bottom: base(1.5);
	padding: 0 $base;

	.template-title {
		margin-top: base(4);
		margin-bottom: base(4);
	}

	.projects-archive-row {
		.toggle-modal {
			@include col(4);
			margin-bottom: $base;
			position: relative;

			&:hover {
				img { opacity: .6; }
				.toggle-modal-hover {
					display: block;
				}
			}
		}

		.toggle-modal-hover {
			@extend %label;
			display: none;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	/////////////////////////////
	// MEDIA QUERIES
	/////////////////////////////

	@include mid-break {
		.projects-archive-row .toggle-modal { @include col(6); }
	}

	@include small-break {
		padding: 0 $gutter;

		.template-title {
			margin-top: base(2);
			margin-bottom: base(2);
		}

		.projects-archive-row .toggle-modal {
			@include col(12);
			margin-bottom: $gutter;
		}
	}
}
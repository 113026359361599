@import '_styles';

.hero-banner {
	@include cms-margin;

    max-width: 100%;
    display: flex;
    align-items: center;

    &.layout-fullWidth {
        .hero-media-wrapper {
            width: 100%;

            .hero-image,
            .hero-video {
                width: 100%;
            }
        }

        .hero-content-wrapper {
            width: 100%;
            display: flex;
            position: absolute;

            &.align-center {
                justify-content: center;
            }

            &.align-right {
                justify-content: flex-end;
            }

            .hero-title {
                h1 {
                    font-size: 22px;
                    margin-bottom: 0px;
                }
            }
        }

        .link-icon-image {
            max-height: 50px;
        }

        
        @include mid-width-break {
            .hero-content-wrapper {
                height: 100%;
            }
        }
    }

    .hero-image,
    .hero-video {
        width: 50vw;
        height: auto;
        overflow: clip;
        overflow-clip-margin: content-box;
    }

    .hero-video video {
        width: 100%;
        height: auto;
    }

    .hero-content-wrapper {
        order: 1;
        width: 50%;
        flex-shrink: 0;
        padding: base(2);

        .hero-title {
            width: calc(100% + #{base(12)});
            position: relative;
            left: 0;
			right: - base(12);
        }
    }

    .hero-media-wrapper {
        order: 2;
    }

    .parallax {
        position: relative;

        &.hero-content-parallax {
            z-index: 1;
        }

        &.hero-media-parallax {
            z-index: 0;
        }
    }

    @include mid-width-break {
        flex-direction: column;
        margin-bottom: base(2);

        .hero-media-wrapper {
            order: 1;
            padding: 0 base(1);

            .hero-image,
            .hero-video {
                position: relative;
                width: 100%;
            }
        }

        &.layout-fullWidth {
            min-height: 375px;

            .hero-content-wrapper {
                height: 375px;
                margin-bottom: 300px;
                padding: 0 base(2);
            }

            .hero-content-parallax {
                margin-top: - base(1);
            }
        }

        .hero-content-wrapper {
            order: 2;
            width: 100%;
            padding: 0 base(1);

            .hero-title,
            .hero-content {
                width: 100%;
            }
    
            .hero-title {
                position: unset;
                left: unset;
                right: unset;
            }
        }
	}

    @media (max-width: #{800px}) {
        &.layout-fullWidth {
            .hero-content-wrapper {
                padding-top: base(3);
            }
        }
    }

    @media (min-width: #{500px}) {
        &.layout-fullWidth {
            .hero-content {
                max-width: 50%;
            }
        }
    }

    @media (min-width: #{800px}) {
        &.layout-fullWidth {
            .hero-content-wrapper {
                height: 100%;
            }

            .hero-content-parallax {
                padding-top: base(2);
            }
        }
    }

    @include mid-width-begin {
        &.layout-fullWidth {
            .hero-content-parallax {
                padding-top: base(6);

                .hero-content {
                    max-width: 33%;
                }

                .hero-title {
                    h1 {
                        font-size: 36px;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
    
    @include large-begin {
        &.layout-fullWidth {
            .hero-content-parallax {
                padding-top: base(7);
                height: 150%;

                .hero-title {
                    h1 {
                        font-size: 48px;
                        margin-bottom: base(2);
                    }
                }
            }
        }
    }

	@include small-break {
        margin-top: $gutter;

        &.layout-fullWidth {
            .hero-content-parallax {
                .hero-title {
                    h1 {
                        font-size: 22px;
                        margin-bottom: 0px;
                    }
                }
            }
        }
	}
}

//////////////////////////////
// BASELINE GRID
//////////////////////////////

$body-font-size: 18px;
$baseline-px: 30px;
$baseline: ($baseline-px / $body-font-size) + rem; // This computes to 30px in base

@function base($multiplier) {
	@return ($baseline-px / $body-font-size) * $multiplier + rem;
}

$base: base(1);

$gutter-rem: 0.5;
$gutter: base($gutter-rem);

/////////////////////////////
// BREAKPOINTS
/////////////////////////////

$tiny-width: 480px;
$mobile-width: 600px;
$tablet-width: 1000px;
$content-width: 1280px;
$reading-width: $tablet-width;
$frame-width: 1440px;
$large-width: 1680px;
$mobile-height: 400px;
$tablet-height: 700px;
$desktop-height: 900px;

//////////////////////////////
// FONTS
//////////////////////////////

$font: 'gt-walsheim', Arial, sans-serif;

//////////////////////////////
// TYPE SIZES
//////////////////////////////

$h1-mega-font-size: base(3.5);
$h1-mega-line-height: base(3.5);

$h1-font-size: base(2.5);
$h1-line-height: base(2.5);

$h2-font-size: base(1.875);
$h2-line-height: base(2);

$h3-font-size: base(1.2);
$h3-line-height: base(1.25);
$h3-letter-spacing: - base(.04);

$h4-font-size: base(.95);
$h4-line-height: base(1);
$h4-letter-spacing: - base(.025);

$h5-font-size: base(.8);
$h5-line-height: base(.9);
$h5-letter-spacing: - base(.02);

$large-font-size: base(.675);
$large-line-height: base(1.25);

$small-font-size: base(.5);
$small-line-height: base(.6);

$label-font-size: base(.5);
$label-letter-spacing: base(.1);

//////////////////////////////
// COLORS
//////////////////////////////

$gray: #B0B0B0;
$dark-gray: #4A4A4A;
$black: #000000;
$black-trans: rgba($black, .5);

$red : crimson;
$green : #3bffaa;

//////////////////////////////
// TRANSITIONS
//////////////////////////////

$trans-time: 200ms;
$trans-curve: cubic-bezier(1.000, 0.255, 0.240, 0.870);

//////////////////////////////
// MISC STYLE
//////////////////////////////

$radius: 0px;
$stroke-width: 2px;

//////////////////////////////
// SHADOWS
//////////////////////////////

%shadow { box-shadow: 0 50px 35px -40px rgba(0, 0, 0, .25), 0 20px 50px -10px rgba(0, 0, 0, .25); }

%inputShadow {
	box-shadow: 0 2px 4px 0 rgba(0, 2, 4, .1), 0 2px 20px 0 rgba(0, 2, 4, .04);
	&:hover { box-shadow: 0 2px 4px 0 rgba(0, 2, 4, .1), 0 5px 20px 0 rgba(0,2,4,.06); }
	&:active, &:focus { box-shadow: 0 2px 4px 0 rgba(0, 2, 4, .1), 0 10px 20px 0 rgba(0, 2, 4, .08); }
}

%textShadow {
	text-shadow: 0 14px 14px rgba($black, .1);
}

//////////////////////////////
// UTILITIES
//////////////////////////////

@mixin color-svg($color) {
	.stroke { stroke: $color; }
	.fill { fill: $color; }
}


@mixin clearfix {

	&:before, &:after {
		content: "";
		display: block;
	}

	&:after { clear: both; }
}

@mixin btn-reset {
	text-align: left;
	border: 0;
	padding: 0;
	cursor: pointer;
	background-color: transparent;
	display: block;
	font-size: inherit;
	font-weight: inherit;
	text-transform: inherit;
	font-family: inherit;
	letter-spacing: inherit;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	&:focus { outline: none; }
}


@import '_styles';

.interact.textarea {
	margin-bottom: base(1);
	position: relative;

	textarea {
		height: auto;
		line-height: 2;
		padding: base(.5) base(.75);
	}

	&.small {
		textarea {
			max-width: 75%;
			@include small-break {
				max-width: 100%;
			}
		}
	}

	@include mid-break {
		margin-bottom: base(.5);
	}
}

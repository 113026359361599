@import '_styles';

.advanced-header {
    @include size-wide-block;
	@include cms-margin;

    margin-top: base(6);

	.advanced-header-outter-wrapper {
		position: relative;
	}

	.advanced-header-content-wrapper {
		display: flex;
		align-items: flex-start;
	}

	.advanced-header-content {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-top: base(2);
	}

	.advanced-header-title {
		width: 50%;
		h2 {
			@extend %textShadow;
			position: relative;
			margin-bottom: 0;
			&:hover { text-decoration: underline; }
		}
	}

	.advanced-header-excerpt { width: calc(33% - #{base(2)}); }

	.advanced-header-down-arrow {
		position: relative;
		margin-top: base(1);
		width: 40px;
		height: 40px;
		color: white;
		svg {
			rotate: 90deg;
			@include color-svg(currentColor);
		}
	}

	.advanced-header-media-wrapper {
		position: absolute;
		top: 0;
		z-index: -1;
		width: 66%;
		&.vertical {
			@include mid-width-break {
				margin-top: - base(8);
			}
		}
	}

	.advanced-header-video {
		width: calc(100% - #{base(2)});
		margin-bottom: - base(4);
		&.advanced-header-video-vertical {
			width: 50%;
			margin-bottom: - base(12);
			margin-left: base(8);
		}
	}

	.advanced-header-image {
		position: relative;
		z-index: -1;
		width: 75%;
		&.advanced-header-image-vertical {
			width: 50%;
		}
	}

	aside {
		@extend %label;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		position: relative;
	}

	.advanced-header-index { display: block; margin-bottom: base(2); }

	.advanced-header-client {
		white-space: nowrap;
		transform: rotate(90deg) translateY(-50%);
		transform-origin: top left;
		position: absolute;
		top: 100%;
		color: $gray;
		left: 50%;
	}

	hr { width: $base; }

	/////////////////////////////
	// EVEN / ODD VARIATIONS
	/////////////////////////////

	&.left {

		.advanced-header-media-wrapper { right: 0; }

		.advanced-header-content {
			align-items: flex-start;
			padding-left: base(2);
			order: 2;
		}

		.advanced-header-video { margin-right: base(2); }

		.advanced-header-image { margin-left: auto; }

		aside { order: 1; }
	}

	&.right {
		.advanced-header-media-wrapper { left: 0; }

		.advanced-header-content-wrapper {
			text-align: right;
			margin-left: auto;
		}

		.advanced-header-title { margin-left: auto; }

		.advanced-header-content {
			padding-right: base(2);
			align-items: flex-end;
		}

		.advanced-header-video { margin-left: base(2); }

		hr { margin-left: auto; }
	}

	/////////////////////////////
	// MEDIA QUERIES
	/////////////////////////////

	@include mid-width-break {

		.advanced-header-title,
		.advanced-header-excerpt { width: 100%; }

		.advanced-header-content { margin-bottom: $base; }

		.advanced-header-media-wrapper {
			position: relative;
			width: calc(100% - #{base(2)});
		}

		&.left {
			.advanced-header-media-wrapper { margin-left: auto; }
		}
	}

	@include small-break {

		.advanced-header-media-wrapper { width: calc(100% - #{base(1.5)}); }

		&.left {
			.advanced-header-content { padding-left: $base; }
		}

		&.right {
			.advanced-header-content { padding-right: $base; }
		}
	}
}

@import '_styles';

.taxonomy-filter-form {
	@extend %label;
	position: fixed;
	z-index: $z-header;
	bottom: 0;
	padding: $base;
	padding-bottom: calc(#{$base} / 2);
	display: flex;
	align-items: center;
	color: $gray;
	width: 50%;
	opacity: 0;
	transition: opacity $trans-time $trans-curve;
	line-height: 1;
	flex-wrap: wrap;

	> *:not(:last-child) { margin-right: $base; }

	.filter-title,
	.checkbox {
		padding-bottom: calc(#{$base} / 2);
	}

	.checkbox {
		@include checkboxInput;
		line-height: unset;
		width: auto;
	}

	&.visible { opacity: 1; }

	/////////////////////////////
	// FORM ELEMENTS
	/////////////////////////////

	@include mid-width-break {
		width: 100%;
	}

	@include small-break {
		padding: $gutter;
		padding-bottom: calc(#{$gutter} / 2);

		> *:not(:last-child) { margin-right: $gutter; }

		.filter-title,
		.checkbox { padding-bottom: calc(#{$gutter} / 2); }
	}
}

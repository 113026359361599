@import '_styles';

.instagram-feed {
	@include gutter;
	@include cms-margin;
	margin: auto;
	width: calc(100% - #{$base});

	.masonry-list {
		@include row;
		list-style: none;
		position: relative;
	}

	.masonry-item {
		@include col(3);
		margin-bottom: $base;
		img { width: 100%; }

		&:focus {
			outline: none;
		}

		&:hover {
			.item-content { display: flex; }
		}
	}

	.item-content {
		display: none;
		position: absolute;
		left: $gutter;
		top: 0;
		width: calc(100% - #{$base} + 1px);
		height: 100%;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: $base;
		overflow: hidden;
		background-color: rgba($black, .8);

		> * {
			margin-bottom: 0;
		}
	}

	.item-meta {
		display: flex;
		align-items: center;
	}

	.social-media {
		display: flex;
		margin-bottom: $gutter;
		background-color: white;
		padding: base(.25);
		color: $black;
		flex-shrink: 0;

		.graphic {
			width: base(.33);
			height: base(.33);
		}
	}

	//////////////////////////////
	// MEDIA QUERIES
	//////////////////////////////

	@include large-break {
		.masonry-item { @include col(4); }
	}

	@include mid-width-break {
		.masonry-item { @include col(6); }
	}

	@include small-break {
		width: 100%;
		.masonry-item {
			@include col(12);
		}
	}
}
@import "_styles";

.card.type-post {

	.card-index {
		@extend %label;
		color: $gray;
	}

	.card-header { position: relative; }

	.card-header-content {
		position: relative;
		z-index: 1;
		width: 66%;
	}

	.card-image {
		position: absolute;
		top: 0;
		right: 0;
		width: 66%;
	}

	.card-title {
		@extend %textShadow;
		&:hover { text-decoration: underline; }
	}

	.card-categories {
		margin-top: $base;
		margin-bottom: $gutter;
	}

	.card-categories,
	.card-title { padding-left: base(2); }

	.card-footer {
		position: relative;
		z-index: 1;
		padding-left: base(4);
	}

	hr {
		width: $base;
		margin: base(1.5) 0;
	}

	/////////////////////////////
	// MEDIA QUERIES
	/////////////////////////////

	@include mid-break {
		.card-header-content { padding-top: $base; }
	}

	@include small-break {
		.card-title,
		.card-categories { padding-left: $base; }

		.card-footer { padding-left: base(2); }
	}

	@include tiny-break {
		.card-title,
		.card-categories,
		.card-footer {
			padding-left: 0;
		}
	}
}
/////////////////////////////
// TRANSITIONS
/////////////////////////////

@keyframes fade-out {
	0% { opacity: 1; }
	100% { opacity: 0; }
}

@keyframes fade-in {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

$duration: 250ms;

.transition-enter,
.transition-exit {
	animation-fill-mode: forwards;
	animation-duration: $duration;
	-webkit-backface-visibility: hidden;
	animation-iteration-count: 1;
}

.transition-enter {
	opacity: 0;
	&.transition-enter-active {
		animation-delay: $duration;
		animation-name: fade-in;
	}
}

.transition-exit {
	&.transition-exit-active {
		animation-name: fade-out;
	}
}
//////////////////////////////
// Z-INDEX
//////////////////////////////

$z-background: -10;
$z-page: 20;
$z-page-content: 30;
$z-video-block: 40;
$z-modal-mask: 70;
$z-modal-content: 80;
$z-header: 90;
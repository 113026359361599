@import '_styles';

.video-block {
	@include size-content-block;
	@include cms-margin;

	position: relative;
	z-index: $z-video-block;

	.video-caption {
		display: block;
		margin-top: $gutter;
		font-style: italic;
		color: $gray;
	}

	//////////////////////////////
	// SIZE VARIATIONS
	//////////////////////////////

	&.size-restricted {
		.video-block-wrapper {
			max-width: $reading-width;
		}
	}

	&.size-centered {
		.video-block-wrapper {
			max-width: $reading-width;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

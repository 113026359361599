@import '_styles';

.modal-mask {
	transition: all $trans-time linear;
	background-color: rgba($black, .8);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $z-modal-mask;
	visibility: hidden;
	opacity: 0;

	&.open {
		opacity: 1;
		visibility: visible;
	}
}

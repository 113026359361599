@import '_styles';

.interact.text,
.interact.email {
	margin-bottom: base(1);
	position: relative;

	@include small-break {
		margin-bottom: base(.5);
	}
}

@import '_styles';

.background-video {
	video {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		z-index: $z-background;
	}
}
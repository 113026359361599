@import '_styles';

.image-block {
	@include size-content-block;
	@include cms-margin;

	img {
		width: 100%;
		max-width: unset;
	}

	.image-caption {
		display: block;
		margin-top: $gutter;
		font-style: italic;
		color: $gray;
	}

	//////////////////////////////
	// SIZE VARIATIONS
	//////////////////////////////

	&.size-restricted {
		.image-block-wrapper {
			max-width: $reading-width;
		}
	}

	&.size-centered {
		.image-block-wrapper {
			max-width: $reading-width;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

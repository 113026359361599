@import '_styles';

a:hover .graphic.hamburger {
	line {
		&:nth-child(1) { transform: translate(-5px, 0); }
		&:nth-child(4) { transform: translate(5px, 0); }
	}
	&.modal-open line {
		&:nth-child(1) { transform: translate(-5px, - base(.5)); }
		&:nth-child(4) { transform: translate(5px, base(.5)); }
	}
}

.graphic.hamburger {
	width: base(.75);

	line {
		transition: all $trans-time;
		transform-origin: center;
		stroke: currentColor;
		stroke-width: $stroke-width;

		&:nth-child(2),
		&:nth-child(3) {
			opacity: 0;
		}
	}

	&.active {
		line {
			&:nth-child(1),
			&:nth-child(4) {
				opacity: 0;
			}

			&:nth-child(2),
			&:nth-child(3) {
				opacity: 1;
			}

			&:nth-child(1) { transform: translate(-5px, - base(.5)); }
			&:nth-child(4) { transform: translate(5px, base(.5)); }
			&:nth-child(2) { transform: rotate(45deg); }
			&:nth-child(3) { transform: rotate(-45deg); }
		}
	}
}

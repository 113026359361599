@import '_styles';

.content-block {
	@include size-content-block;
	@include cms-margin;

	.column:last-child:not(.has-button) .html-block > *:last-child {
		margin-bottom: 0;
	}

	.optional-button.centered {
		text-align: center;
	}


	//////////////////////////
	// Media Queries
	//////////////////////////

	@include small-break {
		.column  {
			@include col(12);
		}
	}
}

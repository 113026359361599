@import '_styles';

.video {
	&.aspect-ratio {
		position: relative;
		height: 0;
		width: 100%;

		video, iframe {
			position: absolute; left: 0; top: 0;
			width: 100%;
			height: 100%;
		}
	}

	video, iframe { background: $black; }
}
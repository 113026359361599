@import '_structure';
@import '_queries';

/////////////////////////////
// GRID
/////////////////////////////

// Gutter
$cols: 12;

/////////////////////////////
// ROWS
/////////////////////////////

@mixin row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -#{$gutter};
	margin-left: -#{$gutter};
}

/////////////////////////////
// COLUMN SETUP
/////////////////////////////

@mixin gutter {
	padding-left: $gutter;
	padding-right: $gutter;
}

@mixin col($col) {
	@include gutter;
	width: $col / $cols * 100%;
}

@mixin max-col($col) {
	@include gutter;
	max-width: $col / $cols * 100%;
}

@mixin push($col) {
	margin-right: $gutter;
	margin-left: calc(#{$col / $cols * 100%} - #{$gutter});
}

/////////////////////////////
// GRID BREAKS
/////////////////////////////

@mixin col-break-large {
	@include large-break {
		width: 50%;
	}
}

@mixin col-break-mid {
	@include mid-break {
		width: 100%;
		padding-left: $gutter;
		padding-right: $gutter;
	}
}

@mixin col-break-mid-half {
	@include mid-break {
		width: 50%;
	}
}

@mixin col-break-small {
	@include small-break {
		width: 100%;
		padding-left: $gutter;
		padding-right: $gutter;
	}
}

@mixin col-break-small-half {
	@include small-break {
		width: 50%;
	}
}

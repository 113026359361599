img.alignright {
	float: right;
	margin: 0 0 $base $base;
}

img.alignleft {
	float: left;
	margin: 0 $base $base 0;
}

img.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.alignright { float: right; }

.alignleft { float: left; }

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

@include small-break {
	img.alignright,
	img.alignleft {
		margin-left: auto;
		margin-right: auto;
		float: none;
		padding-right: 0;
		padding-left: 0;
	}
}
@import '_styles';

.image-grid-modal {
	padding-top: base(2);
	padding-bottom: base(2);

	.modal-inner-wrapper {
		@include size-content-block;
	}

	.modal-title-wrapper {
		display: flex;
		margin-bottom: base(2);
	}

	.modal-title {
		margin-bottom: 0;
		margin-right: auto;
	}

	iframe { background: black; }

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

@import '_styles';

.template-proposal.authenticated {
	.flexible-content {
		.content-block {
			@include size-reading-block;
		}

		.image-block {
			img,
			.image-caption {
				margin-left: auto;
				margin-right: auto;
			}
		}

		.video-block {
			.video,
			.video-caption {
				margin-left: auto;
				margin-right: auto;
			}
		}

		.blockquote .blockquote-inner-wrapper {
			@include size-reading-block;
			margin: auto;
		}
	}
}
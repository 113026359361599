@import '_vars';
@import '_structure';
@import '_queries';

@mixin formStatus () {
	@extend %label;
	height: auto;
	max-height: 0;
	opacity: 0;
	margin-bottom: 0;
	transition: max-height 300ms ease-out,
		opacity 300ms ease,
		margin-bottom 300ms ease,
		padding 300ms ease;
	background: black;
	color: white;
	padding: 0;

	&.open {
		padding: base(.5);
		max-height: 400px;
		opacity: 1;
		margin-bottom: $base;
	}

	&.error {
		background: $red;
	}
}

@mixin formInput () {
	width: 100%;
	border: solid $stroke-width rgba($gray, 0.4);
	background-color: transparent;
	color: white;
	border-radius: 0;
	font-size: base(.65);
	height: base(2);
	line-height: base(2);
	padding: 0 base(.75);
	-webkit-appearance: none;

	&::placeholder { color: $gray; }

	&:focus,
	&:active { outline: 0; }

	&:focus {
		border-color: rgba($gray, 0.8);
	}

	@include small-break {
		margin-bottom: base(.5);
	}
}

@mixin formSelect () {
	width: 100%;
	border: solid $stroke-width rgba($gray, 0.4);
	background-color: transparent;
	color: white;
	border-radius: 0;
	font-size: base(.75);
	height: base(2);
	line-height: base(2);
	padding: 0 base(.75);
	-webkit-appearance: none;

	&::placeholder { color: $gray; }

	&:focus,
	&:active { outline: 0; }

	&:focus {
		border-color: rgba($gray, 0.8);
	}

	@include small-break {
		margin-bottom: base(.5);
	}
}

@mixin checkboxInput () {
	font-family: $font;
	width: 100%;
	color: $gray;
	font-size: base(.65);
	line-height: base(1.75);
	display: flex;
	align-items: center;

	label { margin-left: $gutter; }

	&::placeholder { color: $black; }

	[type="checkbox"],
	[type="input"] {
		-webkit-appearance: none;
		border: $stroke-width solid currentColor;
	}

	[type="checkbox"] {
		width: base(.75);
		height: base(.75);
		position: relative;
		padding: base(.25);
		border-radius: 0;
		margin: 0;
		color: currentColor;
		cursor: pointer;

		+ label { cursor: pointer; }

		&::before,
		&::after {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: base(.5);
			height: $stroke-width;
			background-color: white;
			opacity: 0;
		}

		&::before { transform: translate(-50%, -50%) rotate(45deg); }

		&::after { transform: translate(-50%, -50%) rotate(-45deg); }

		&:checked {
			color: white;
			&::before,
			&::after {
				opacity: 1;
			}
		}
	}
}

@mixin radioInput () {
	font-family: $font;
	width: 100%;
	color: $gray;
	font-size: base(.65);
	line-height: base(1.75);
	display: flex;
	align-items: center;

	label { margin-left: $gutter; }

	&::placeholder { color: $black; }

	[type="radio"],
	[type="input"] {
		-webkit-appearance: none;
		border: $stroke-width solid currentColor;
	}

	[type="radio"] {
		width: base(.75);
		height: base(.75);
		position: relative;
		padding: base(.25);
		border-radius: 100%;
		margin: 0;
		color: currentColor;
		cursor: pointer;

		+ label { cursor: pointer; }

		&::before {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: base(.25);
			height: base(.25);
			border-radius: base(.25);
			background-color: white;
			opacity: 0;
		}

		&:checked {
			color: white;
			&::before {
				opacity: 1;
			}
		}
	}
}
@import '_styles';

.modal {
	transform: translateZ(0);
	transition: all 500ms;
	opacity: 0;
	visibility: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;

	.modal-wrap {
		@include size-content-block;
		padding-top: base(2);
		padding-bottom: base(2);
	}

	> * {
		-webkit-overflow-scrolling: touch;
		overflow-x: hidden;
		height: 100%;
	}

	&.open {
		opacity: 1;
		visibility: visible;
		z-index: $z-modal-content;
	}
}

@import '_styles';

.person-modal {
	padding-top: base(2);
	padding-bottom: base(2);

	.row {
		@include size-content-block;
		align-items: flex-start;
	}

	.modal-image {
		@include col(6);
		position: relative;
	}

	.modal-content {
		@include col(6);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.close-modal {
		align-self: flex-end;
		position: absolute;
		top: base(1);
		left: base(1.5);
	}

	/////////////////////////////
	// EVEN / ODD VARIATIONS
	/////////////////////////////

	@include mid-width-break {
		.row { flex-direction: column; }

		.modal-image,
		.modal-content {
			@include col(12);
		}

		.modal-content {
			margin-top: base(1.5);
		}
	}
}
@import '_fonts';
@import '_styles';
@import '_slider';
@import '_transitions';
@import '_wp';

/////////////////////////////
// DEFAULTS
/////////////////////////////

* { box-sizing: border-box; }

html, body, #root { height: 100%; }

html {
	background: $black;
	font-size: $body-font-size;
	line-height: $baseline-px;
	position: relative;

	@include mid-break { font-size: 16px; }
	@include small-break { font-size: 14px; }
}

body {
	margin: 0;
	overflow-x: hidden;
	font-family: $font;
	font-weight: 400;
	color: white;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img {
	max-width: 100%;
	height: auto;
	display: block;

	&.inline { display: inline-block; }
}

::selection {
	background: white;
	color: $black;
}

/////////////////////////////
// TYPE
/////////////////////////////

h1 { @extend %h1; }

h2 { @extend %h2; }

h3 { @extend %h3; }

h4 { @extend %h4; }

h5 { @extend %h5; }

p { @extend %p; }

a { @extend %anchor; }

.large-body { @extend %large-body; }

.label { @extend %label; }

/////////////////////////////
// GRID
/////////////////////////////

.row { @include row; }
.col { @include gutter; }
.col3 { @include col(3); }
.col4 { @include col(4); }
.col6 { @include col(6); }
.col9 { @include col(9); }
.col12 { @include col(12); }

/////////////////////////////
// FORM ELEMENTS
/////////////////////////////

input { @include formInput; }

select { @include formSelect; }

textarea { @include formInput; };

label { @extend %label; }

.status { @include formStatus; }

.interact {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	&.error {
		&:first-child { margin-top: 0; }

		textarea,
		input,
		select {
			border: $red solid $stroke-width;
		}
	}

	label {
		display: flex;
		margin-bottom: base(.35);
		line-height: 1.5;
	}

	.required {
		color: $red;
		margin-left: base(.25);
		margin-right: auto;
	}
}

/////////////////////////////
// MISC ELEMENTS
/////////////////////////////

hr {
	border: 0;
	border-top: $stroke-width solid $gray;
	margin: base(2) 0;

	@include mid-break { margin: base(1) 0; }
}

ul {
	padding: 0;
	padding-left: base(1.5);
	margin: 0;
}

/////////////////////////////
// CLASSES
/////////////////////////////

.template-wrap {
	display: flex;
	flex-direction: column;
}

svg.graphic {
	@include color-svg($black);
	overflow: visible;

	path, line, polyline {
		vector-effect: non-scaling-stroke;
		fill: none;
	}

	.stroke { stroke-width: $stroke-width; }
}

@import '_styles';

.template-proposal.unauthenticated {

	.proposal-outer-wrapper {
		@include size-content-block;
		padding-top: base(2);
		padding-bottom: base(2);
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.proposal-inner-wrapper {
		width: 50%;
		margin: auto;
	}

	form {
		display: flex;

		.interact {
			width: 100%;
			max-width: 500px;
		}
	}

	/////////////////////////////
	// MEDIA QUERIES
	/////////////////////////////

	@include small-break {
		.proposal-inner-wrapper {
			width: 100%;
		}
	}
}
@import '_styles';

.view-header {
	position: relative;

	.header-content-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		> .html-block:last-of-type > *:last-child { margin-bottom: 0; }

		.btn,
		.header-scroll-to { margin-top: $base; }
	}

	/////////////////////////////
	// STYLE VARIATIONS
	/////////////////////////////

	// default

	&.type-default {
		margin-top: base(4);
		margin-bottom: base(4);

		.header-content-wrapper {
			@include size-content-block;
		}
	}

	// fullscreen

	&.type-fullscreen {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: base(3);

		.header-content-wrapper {
			@include size-content-block;
			padding-top: base(4);
			padding-bottom: base(4);
		}

		&.has-background-video {
			overflow: hidden;

			.header-video {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				min-width: 100%;
				min-height: 100%;
				width: auto;
				height: auto;
				z-index: -1;
			}
		}

		.header-scroll-to {
			@extend %label;
			display: flex;
			align-items: center;
			color: $gray;
			transform: rotate(90deg);
			transform-origin: left bottom;
			background: none;
			border: 0;
			padding: 0;
			cursor: pointer;
			transition: color $trans-curve $trans-time;

			&:focus { outline: none; }

			&::after {
				content: '';
				display: inline-block;
				width: base(2);
				margin-left: $gutter;
				height: $stroke-width;
				background-color: currentColor;
				transition: width $trans-curve $trans-time;
			}

			&:hover {
				color: white;
				&::after { width: base(2.5); }
			}
		}
	}

	// image content

	&.type-image-content {
		@include size-content-block;
		position: relative;
		margin-bottom: base(4);
		margin-top: base(1);
		display: flex;
		align-items: center;

		.header-content-wrapper {
			order: 1;
			width: 50%;
			flex-shrink: 0;
			padding: base(2) 0;
		}

		.header-image {
			width: 50vw;
			height: auto;
			order: 2;
		}

		.header-title {
			position: relative;
			width: calc(100% + #{base(12)});
			left: 0;
			right: - base(12);
		}

		.header-content {
			padding-right: base(2);

			&::after {
				content: '';
				background-color: white;
				height: $stroke-width;
				width: base(2);
				display: block;
				margin-top: base(2);
			}
		}
	}

	/////////////////////////////
	// MEDIA QUERIES
	/////////////////////////////

	@include mid-width-break {
		&.type-image-content {
			flex-direction: column;
			margin-bottom: base(2);

			.header-image {
				position: relative;
				width: 100%;
				order: 1;
			}

			.header-title,
			.header-content {
				width: 100%;
			}

			.header-content-wrapper {
				order: 2;
				width: 100%;
				padding-top: base(2);
				padding-bottom: 0;
			}

			.header-content {
				padding-right: 0;
			}
		}
	}

	@include small-break {

		&.type-default {
			margin-top: base(2);
			margin-bottom: base(2);
		}

		&.type-image-content {
			margin-top: $gutter;
		}

		&.type-fullscreen {
			.header-content-wrapper {
				padding-top: base(2);
				padding-bottom: base(2);
			}
		}
	}
}